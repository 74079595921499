<template>
  <div class="pt-6">
    <vs-prompt
      :accept-text="$t('Accept')"
      :cancel-text="$t('Cancel')"
      title="Delete Request"
      @accept="DeleteCreditRequest"
      :active.sync="activeDeleteRequest"
      color="dark"
    >
      <div class="con-exemple-prompt">
        <span> Do you want to delete request? </span>
      </div>
    </vs-prompt>
    <!-- CREDIT PAGE -->
    <transition name="fade" mode="out-in">
      <div key="credit" v-if="!paymentPageActive">
        <!-- Credit Buy -->

        <div class="p-2">
          <vx-card class="p-1">
            <div
              style="
                font-size: 21.14px;
                font-family: inherit;
                font-weight: 500;
                line-height: 1.2;
                color: #2c2c2c;
              "
            >
              Buy Credit
            </div>

            <div class="vx-row mt-4 pl-4">
              <div class="vx-col md:w-1/2 w-full">
                <div class="vx-row items-end">
                  <vs-input
                    class="mr-2"
                    label="How many credits you need"
                    v-model="credit"
                  />
                  <vs-button
                    class="mt-2"
                    style="max-height: 39px"
                    type="filled"
                    @click="SendCreditRequest()"
                    >Calculate Cost</vs-button
                  >
                </div>
              </div>
              <div
                v-if="creditRequestDetail.hasOwnProperty('amount')"
                class="vx-col md:w-1/2 w-full mt-2 p-4"
                style="background-color: #ebebeb; border-radius: 5px"
              >
                <p class="creditPriceArea mb-2">
                  Price For {{ creditRequestDetail.amount }} Credits
                </p>
                <p
                  class=""
                  style="color: #afafaf;font-weight;font-size: 0.9rem"
                >
                  1 Credit = $
                  {{
                    (creditRequestDetail.tax != 0
                      ? creditRequestDetail.sub_price
                      : creditRequestDetail.price) / creditRequestDetail.amount
                  }}
                </p>
                <p v-if="creditRequestDetail.tax != 0" class="creditTaxArea">
                  $ {{ creditRequestDetail.sub_price.toFixed(1) }} +
                  {{
                    (
                      creditRequestDetail.price - creditRequestDetail.sub_price
                    ).toFixed(1)
                  }}
                  TAX
                </p>

                <p class="creditPriceArea">$ {{ creditRequestDetail.price }}</p>
                <vs-button
                  class="float-right"
                  color="success"
                  type="filled"
                  @click="SendBuyRequest()"
                  >Checkout Now</vs-button
                >
              </div>
            </div>
          </vx-card>
        </div>

        <!-- Credit History -->
        <div class="flex flex-wrap">
          <div class="vx-col p-2 w-full">
            <vx-card class="p-1">
              <vs-table :data="creditHistory">
                <template slot="header">
                  <h3 class="mt-2 mb-2">
                    {{ $t("Credit_Purchase_History") }}
                  </h3>
                </template>
                <template slot="thead">
                  <vs-th>
                    {{ $t("Title") }}
                  </vs-th>
                  <vs-th>
                    {{ $t("Amount") }}
                  </vs-th>
                  <vs-th>
                    {{ $t("Status") }}
                  </vs-th>
                  <vs-th>
                    {{ $t("Type") }}
                  </vs-th>
                  <vs-th>
                    {{ $t("Date") }}
                  </vs-th>
                  <vs-th>
                    {{ $t("Price") }}
                  </vs-th>
                  <vs-th> </vs-th>
                </template>

                <template slot-scope="{ data }">
                  <vs-tr :key="indextr" v-for="(tr, indextr) in data">
                    <vs-td :data="data[indextr].title">
                      {{ data[indextr].title }}
                    </vs-td>

                    <vs-td :data="data[indextr].amount">
                      {{ data[indextr].amount }} </vs-td
                    ><vs-td class="justify-center" :data="data[indextr].status">
                      <vs-chip
                        :color="GetOrderStatusColor(data[indextr].status)"
                        >{{ $t(data[indextr].status) }}</vs-chip
                      > </vs-td
                    ><vs-td :data="data[indextr].payment_method">
                      {{ data[indextr].payment_method }} </vs-td
                    ><vs-td :data="data[indextr].created">
                      {{ GetRelativeTime(data[indextr].created) }}
                    </vs-td>
                    <vs-td :data="data[indextr].price">
                      {{ CreditPrice(data[indextr]) }}
                    </vs-td>
                    <vs-td>
                      <feather-icon
                        style="cursor: pointer"
                        v-if="data[indextr].status == 'New'"
                        icon="TrashIcon"
                        svgClasses="w-5 h-5 hover:text-danger "
                        @click="
                          deleteCredit = data[indextr].id;
                          activeDeleteRequest = true;
                        "
                      />
                    </vs-td>
                  </vs-tr>
                </template>
              </vs-table>
            </vx-card>
          </div>
          <div class="m-2 vx-col flex justify-center w-full">
            <vs-pagination
              :max="5"
              :total="totalPage"
              v-model="currentPage"
              @change="HandleChangePage(currentPage)"
            />
          </div>
        </div>
      </div>
      <div key="payment" v-else>
        <div class="vx-card p-4">
          <div class="vx-row">
            <div class="vx-col w-full text-right">
              <vs-button @click="paymentPageActive = false" type="border">
                {{ $t("Back") }}
              </vs-button>
            </div>
          </div>
        </div>

        <vs-row class="mt-4" vs-type="flex" vs-justify="center">
          <vs-col vs-type="flex" vs-justify="center" vs-lg="6" vs-sm="12">
            <vx-card>
              <div
                class="wfull p-2"
                :key="bankInfo.title"
                v-for="(bankInfo, index) in bankInfos"
                :style="
                  index % 2 == 0
                    ? 'background-color: #f5f5f5; border-radius:5px'
                    : ''
                "
              >
                <p style="font-weight: 600" class="p-2">
                  {{ bankInfo.title }}
                </p>

                <p class="bankIban p-2">{{ bankInfo.iban }}</p>
              </div>
            </vx-card>
          </vs-col>
        </vs-row>
      </div>
    </transition>
  </div>
</template>

<script>
import Api from "@/api/api";
import API from "@/api/config";

import moment from "moment";
export default {
  data() {
    return {
      credit: 0,
      paymentPageActive: false,
      creditRequestDetail: {},
      activeDeleteRequest: false,
      deleteCredit: null,

      creditHistory: [],

      //Pagination
      currentPage: 1,
      totalPage: 1,
      bankInfos: [
        {
          title: "İŞ BANKASI (TL)  MASLAK TİCARİ ",
          iban: "TR53 0006 4000 0011 3990 0194 62 ",
        },
        {
          title: "İŞ BANKASI (USD)  MASLAK TİCARİ (1399) 30092 ISBKTRIS ",
          iban: "TR28 0006 4000 0021 3990 0300 92 ",
        },
        {
          title: "TEB (TL)  MAÇKA ",
          iban: "TR33 0003 2000 0000 0018 1165 02 ",
        },
        {
          title: "TEB (USD)  MAÇKA (383) 17877246 TEBUTRISXXX",
          iban: "TR36 0003 2000 0000 0017 8772 46",
        },
        {
          title: "VAKIFBANK TL ETİLER",
          iban: "TR06 0001 5001 5800 7301 5759 91",
        },
        {
          title: "VAKIFBANK USD ETİLER",
          iban: "TR16 0001 5001 5804 8018 5423 85",
        },
        {
          title: "AKBANK TL ZİNCİRLİKUYU",
          iban: "TR11 0004 6004 3688 8000 2090 99",
        },
        {
          title: "AKBANK USD ZİNCİRLİKUYU",
          iban: "TR32 0004 6004 3600 1000 2091 00",
        },
      ],
    };
  },
  methods: {
    DeleteCreditRequest() {
      Api.delete(
        API.BASEURL + API.NOKTA_MARKET_CREDIT_HISTORY + this.deleteCredit + "/",
        this.HandleDeleteCreditRequest
      );
    },
    HandleDeleteCreditRequest(status) {
      if (status == 204) {
        this.GetCreditHistory();
        this.$vs.notify({
          title: "Success",
          text: "Your request has been deleted.",
          color: "success",
          icon: "check",
          time: 3000,
        });
      }
    },
    SendBuyRequest() {
      Api.post(
        API.BASEURL + API.NOKTA_MARKET_CREDIT + this.credit + "/",
        this.HandleSendBuyRequest
      );
    },
    HandleSendBuyRequest(status, data) {
      if (status == 200) {
        this.creditRequestDetail = {};
        this.$vs.notify({
          title: "Success",
          text: "Your request has been sent",
          color: "success",
          icon: "check",
          time: 3000,
        });
        this.GetCreditHistory();
        this.credit = 0;
        this.paymentPageActive = !this.paymentPageActive;
      }
      if (status == 400) {
        if (data.data.pending) {
          this.$vs.notify({
            title: "Warning",
            text: data.data.pending,
            color: "danger",
            icon: "error",
            time: 3000,
          });
        }
      }
    },
    SendCreditRequest() {
      Api.get(
        API.BASEURL + API.NOKTA_MARKET_CREDIT + this.credit + "/",
        this.HandleSendCreditRequest
      );
    },
    HandleSendCreditRequest(status, data) {
      if (status == 200) {
        this.creditRequestDetail = data;
      }

      if (status == 400) {
        if (data.amount) {
          this.$vs.notify({
            title: "Warning",
            text: data.amount[0],
            color: "danger",
            icon: "error",
            time: 3000,
          });
        }
      }
    },
    GetCreditHistory() {
      Api.get(
        API.BASEURL + API.NOKTA_MARKET_CREDIT_HISTORY,
        this.HandleGetCreditHistory
      );
    },
    HandleGetCreditHistory(status, data) {
      if (status == 200) {
        this.creditHistory = data.results;
      }
    },
    HandleChangePage() {
      //Get Data
    },
    GetRelativeTime(value) {
      return moment(String(value)).format("DD/MM/YYYY - hh:mm");
    },
    GetOrderStatusColor(status) {
      if (status == "Approved") return "success";
      if (status == "Cancelled") return "danger";

      return "primary";
    },
    GetCurrencyUnit(calPackage) {
      let price = parseFloat(
        (calPackage.price / calPackage.amount).toFixed(2).toString()
      );
      if (calPackage.price_currency == "USD") {
        return "$" + price;
      } else if (calPackage.price_currency == "TRY") {
        return price + "₺";
      } else if (calPackage.price_currency == "EUR") {
        return price + "€";
      }
    },
    CreditPrice(credit) {
      if (credit.currency == "USD") {
        return "$" + credit.price;
      } else if (credit.currency == "TRY") {
        return credit.price.toFixed(0) + "₺";
      } else if (credit.currency == "EUR") {
        return credit.price.toFixed(0) + "€";
      }
    },
    CreditPackageTitleBar(index) {
      if (index == 0) {
        return {
          "background-color": "#cbcbcb",
        };
      } else if (index == 1) {
        return {
          "background-color": "#D0B047",
        };
      } else if (index == 2) {
        return {
          "background-color": "#96A0A2",
        };
      } else if (index == 3) {
        return {
          "background-color": "#34AA10",
        };
      } else if (index == 4) {
        return {
          "background-color": "#D2DAE2",
        };
      }
    },
  },
  watch: {
    credit(newValue) {
      if (newValue < 0) {
        this.credit = 0;
      }
    },
  },
  created() {
    this.GetCreditHistory();
  },
};
</script>

<style scoped>
.creditCards {
  width: 100%;
  min-width: 210px !important;
  max-width: 300px !important;
}
.packageDiv {
  background-color: white;
  box-shadow: 0px 4px 25px 0px rgba(0, 0, 0, 0.1);
  border-radius: 10px;
}
.creditPackageTitle {
  color: white;
  font-weight: 600;
  font-size: 1.2em;
  text-shadow: 0px 2px 2px #00000029;
  border-top-right-radius: 10px;
  border-top-left-radius: 10px;
}
.creditTitle {
  font-size: 18px;
  font-weight: 600;
  text-shadow: 0px 2px 2px #00000029;
}
.creditSubTitle {
  font-size: 14px;
  font-weight: 600;
  text-shadow: 0px 2px 2px #00000029;
}
.creditCartInfo {
  border-radius: 0.5rem;
  box-shadow: 0px 4px 25px 0px rgba(0, 0, 0, 0.1);
  width: 100%;
  padding: 20px;
  margin: auto;
  background-color: #fff;
}
.maxWidth {
  max-width: 1000px;
}
.creditPriceArea {
  font-size: 1.5rem;
  font-weight: 600;
}
.creditTaxArea {
  font-size: 1.2rem;
  font-weight: 500;
}
</style>
